@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-sky-950;
  }
}

@layer components {
  .form-input:focus + .form-label,
  .form-input:not(:placeholder-shown) + .form-label {
    @apply text-xs text-blue-500;
  }
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

body {
  --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}